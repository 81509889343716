// Mixins
// Background Cover Mixin
@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Font Mixins
@mixin serif-font {
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
@mixin script-font {
  font-family: 'Kaushan Script', 'Helvetica Neue', Helvetica, Arial, cursive;
}
@mixin body-font {
  font-family: 'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
@mixin heading-font {
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
